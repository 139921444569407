// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

const controller = new ScrollMagic.Controller()
const $scroller = document.getElementById('scroller')
const $textGroup = document.getElementById('text-group')

let canTweenOpts = {y: '-100%'}

if (window.matchMedia('(min-width: 768px)').matches) {
  canTweenOpts = {x: '-280%'}
}

const canTween = TweenMax.to('.can-group', 1, canTweenOpts)

let labelTweenOpts = {y: '-90%'}

if (window.matchMedia('(min-width: 768px)').matches) {
  labelTweenOpts = {x: '-90%'}
}

const labelTween = TweenMax.to('.label-wrapper', 1, labelTweenOpts)

const checkProgress = () => {
  const progress = timeline.progress() * 100

  if (progress > 60) {
    $textGroup.classList.add('show-text')
  } else {
    $textGroup.classList.remove('show-text')
  }
}

const timeline = new TimelineMax({ onUpdate: checkProgress })
  .add(canTween, 0)
  .add(labelTween, 0)

const scene = new ScrollMagic.Scene({
  triggerElement: '#scroller',
  triggerHook: 'onLeave',
  duration: '300%'
})
  .setPin('#scroller')
  .setTween(timeline)
  .addTo(controller)

window.onbeforeunload = () => {
  document.documentElement.scrollTop = 0
}
